import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m6441 4189 c19 -6 40 -19 47 -27 13 -17 17 -209 4 -228 -26 -40 32 -39
-2007 -41 -2144 -3 -1987 2 -2185 -63 -117 -38 -145 -52 -252 -116 -278 -169
-473 -456 -528 -780 -20 -113 -20 -172 -20 -2610 0 -1869 -3 -2499 -12 -2518
-28 -64 -243 -62 -277 2 -8 15 -11 744 -11 2504 0 2626 0 2613 45 2783 30 113
99 274 165 385 95 160 185 263 325 374 232 184 373 248 670 306 83 16 159 31
170 33 11 3 878 5 1926 6 1391 1 1915 -2 1940 -10z m1067 -111 c138 -51 232
-140 305 -286 l52 -105 0 -171 c-1 -170 -1 -172 -34 -254 -62 -155 -150 -266
-295 -372 -60 -44 -167 -90 -205 -90 -31 1 -53 29 -65 82 -13 59 -29 72 -136
103 -105 32 -163 66 -236 140 -142 144 -194 338 -145 542 25 102 33 123 80
192 79 116 206 206 326 230 76 15 301 8 353 -11z m1267 8 c103 -28 139 -50
241 -151 133 -131 174 -271 154 -520 -20 -246 -183 -461 -444 -585 -71 -33
-110 -40 -128 -22 -5 5 -12 29 -15 53 -8 76 -18 86 -111 116 -88 28 -225 103
-275 151 -41 39 -95 125 -124 195 -34 84 -43 261 -19 357 18 71 79 181 132
240 74 82 197 149 319 174 49 9 224 5 270 -8z m371 -1541 l24 -24 -2 -2488 -3
-2488 -23 -115 c-27 -136 -36 -162 -98 -304 -39 -90 -130 -248 -153 -266 -3
-3 -26 -32 -50 -65 -69 -94 -193 -209 -307 -286 -181 -121 -351 -191 -566
-231 l-123 -23 -1962 0 -1962 0 -23 23 c-21 21 -23 32 -23 128 0 99 1 107 24
125 23 19 67 19 1976 19 1918 0 1955 0 2051 20 191 39 343 107 489 219 82 63
220 210 260 276 60 99 135 260 145 313 7 31 19 82 28 112 16 50 17 271 22
2552 5 2482 5 2497 25 2512 15 11 47 15 123 16 96 0 104 -2 128 -25z m-6281
-1250 c80 -27 177 -78 228 -121 16 -13 33 -24 39 -24 5 0 28 15 51 33 191 153
470 189 707 94 199 -81 342 -233 424 -451 l31 -81 3 -962 3 -963 -240 0 -240
0 -3 923 c-3 1024 2 940 -70 1022 -48 55 -101 79 -178 79 -99 0 -174 -47 -224
-139 -21 -40 -21 -40 -24 -962 l-3 -923 -239 0 -240 0 0 903 c0 1022 3 974
-75 1052 -112 113 -294 89 -380 -49 l-30 -49 -3 -929 -2 -928 -243 2 -242 3
-3 880 c-2 567 1 913 8 972 31 275 208 505 465 604 105 40 161 49 290 44 87
-2 128 -9 190 -30z m2947 21 c247 -49 450 -207 568 -441 89 -178 83 -109 87
-1142 l4 -913 -246 0 -245 0 0 440 0 440 -322 -2 -323 -3 -3 -437 -2 -438
-240 0 -240 0 0 440 0 440 -85 0 c-84 0 -85 0 -91 28 -3 15 -31 116 -60 226
-30 109 -54 207 -54 217 0 18 10 19 144 19 l144 0 4 228 c5 253 15 314 73 437
41 88 136 215 205 275 107 93 291 180 410 193 83 9 204 6 272 -7z m2428 -226
l0 -239 -442 -3 c-409 -3 -446 -4 -477 -22 -71 -38 -101 -89 -101 -169 0 -101
35 -132 340 -294 175 -93 502 -280 542 -310 109 -80 211 -217 260 -349 32 -88
32 -300 1 -400 -74 -233 -277 -420 -509 -469 -56 -12 -176 -15 -627 -15 l-557
0 2 240 3 240 545 3 c487 2 548 4 575 19 77 42 124 131 110 207 -16 88 -66
133 -260 237 -103 55 -202 109 -330 181 -49 28 -112 63 -140 78 -224 124 -297
191 -369 336 -103 208 -101 410 6 612 91 171 277 308 469 346 24 5 250 9 502
10 l457 1 0 -240z m-5163 -3574 c7 -6 17 -34 22 -61 9 -51 38 -85 70 -85 75 0
255 -102 331 -187 201 -223 180 -591 -46 -802 -109 -102 -231 -151 -375 -151
-110 0 -241 29 -284 63 -5 4 -30 21 -55 37 -99 64 -184 180 -222 303 -18 59
-20 86 -16 210 5 128 8 150 36 227 74 200 233 365 429 446 57 23 86 23 110 0z
m-1312 -11 c16 -15 25 -36 25 -55 0 -41 37 -77 86 -85 169 -29 319 -148 406
-323 l43 -86 0 -136 c0 -154 -12 -201 -75 -310 -62 -105 -166 -199 -265 -238
-156 -62 -366 -52 -499 22 -115 64 -173 122 -227 229 -63 123 -69 149 -70 285
0 188 30 290 129 438 64 94 155 169 306 251 74 39 108 42 141 8z"/>
<path d="M5593 6240 c-102 -21 -196 -101 -234 -198 -20 -50 -23 -80 -27 -254
l-4 -198 326 0 326 0 0 199 c0 235 -10 276 -88 356 -78 80 -194 117 -299 95z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
